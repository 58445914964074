export const myanmarDailySaleReport = {
  main_title: "နေ့စဉ်အရောင်းစာရင်း",
  detail_title: "အရောင်းအသေးစိတ်အစီရင်ခံစာ",
  start_date: "စတင်ရက်",
  end_date: "ပြီးဆုံးရက်",
  purpose_of_use: "ယာဉ်အမျိုးအစား",
  nozzle_no: "ဆီဂေါက်နံပါတ်",
  fuel_type: "ဆီအမျိုးအစား",
  station: "စတင်ရှင်",
  amount: "ပမာဏ (ကျပ်)",
  casher: "ရောင်းသူ",
  toExcel: "Excel သို့ပြောင်းရန်",
  toPrint: "Print ထုတ်ရန်",
  search: "ရှာဖွေသည်",
  title: "၏နေ့စဉ် အရောင်းမှတ်တမ်း ဇယား",
  total_record: "စုစုပေါင်း မှတ်တမ်း",
  cashType: "အရောင်းအမျိုးအစား",
  pprd_no: "PPRD လိုင်စင်နံပါတ်",
  vocono: "ဘောင်ချာ",
  sale_date_time: "ရောင်းချိန်",
  vehicle_no: "ယာဉ်နံပါတ်",
  sale_gallon: "ဂါလန်အရောင်း",
  sale_liter: "လီတာအရောင်း",
  sale_price: "ရောင်းစျေး",
  total_price: "စုစုပေါင်းအရောင်း",
  totalizer_liter: "Totalizer လီတာ",
  totalizer_amount: "Totalizer ပမာဏ",
  Station_name: "ဆိုင်အမည်", //update
  Township: "မြို့နယ်",
  State: "ပြည်နယ်/တိုင်း",
  Open: "အဖွင့် စတော့ (ဂါလံ)",
  Close: "အပိတ်စတော့ (ဂါလံ)",
  Sale: "ရောင်းချမှုပမာဏ (ဂါလံ)",
  Adj: "ချိန်ညှိမှု (ဂါလံ)",
  Remark: "မှတ်ချက်",
  receive: "ဆီလက်ခံ (ဂါလံ)",
  pump_title: "ပန့်အစီရင်ခံစာ",
};
