export const MyanmarMainConDashboard = {
  dashboard: "ဒက်ရှ်ဘုတ်",
  greeting: "ဒက်ရှ်ဘုတ် မှကြိုဆိုပါသည်။",
  to_reports: "စာရင်းများသို့",
  total_vehicles: "စုစုပေါင်းယာဉ်အမျိုးအစား",
  total_liters: "စုစုပေါင်း လီတာ",
  total_gallon: "စုစုပေါင်း ဂါလန်",
  total_sale: "စုစုပေါင်း အရောင်း",
  vehicle_type: "ယာဉ်အမျိုးအစား",
  today_table: "ယနေ့ဇယား",
  today_vehicle: "ယနေ့ ယာဉ်အမျိုးအစားများ",
  total_vehicle: "စုစုပေါင်း ယာဉ်များ",
  seven_day: "(တစ်ပတ်)",
};
