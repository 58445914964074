export const EnglishFuelBalance = {
  sub: "Daily Sale Report",
  main_title: "Fuel Balance Report",
  title: "Fuel In Report",
  title1: "Daily Sale Report",
  title2: "Tank Report",
  t: "Fuel In Report",
  date: "Date",
  s_date: "From Date",
  e_date: "To Date",
  tank_no: "Tank No.",
  station: "Station",
  search: "SEARCH",
  fuel_type: "Fuel Type",
  tank: "Tank",
  pprd_no: "PPRD License No.",
  capacity: "Capacity",
  opening: "Opening stock (Gallon)",
  receive_volume: "Received (Gallon)",
  opening1: "Opening stock (Liter)",
  receive_volume1: "Receive (Liter)",
  liter: "Li",
  gallon: "Gallon",
  sale: "Sale Volume (Gallon)",
  sale1: "Sale Volume Liter",
  balance: "Closing Stock (Gallon)",
  sale1: "Sale Volume Liter",
  balance1: "Closing Stock (Liter)",
  toExcel: "To Excel",
  toPrint: "To PDF",
  Station_name: "Station Name", //update
  Township: "Township",
  State: "State/Division",
  Open: "Opening Stock (Gallon)",
  Close: "Closing Stock (Gallon)",
  Sale: "Sale Volume (Gallon)",
  Adj: "Adjustment (Gallon)",
  Remark: "Remark",
  receive: "Received (Gallon)",
  closing_li: "Closing Liter",
  closing_stk: "Closing Stock (Gallon)",
  closing_stk1: "Closing Stock (Liter)",
  dif: "Difference Liter",
  gl: "Adjustment (Gallon)",
  gl1: "Gain/Loss ",
  gainlose: "Gain/Loss (Gallon)",
};
