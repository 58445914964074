export const EnglishDailySaleSummaryReport = {
  main_title: "Daily Sale Summary Report",
  start_date: "From Date",
  end_date: "To Date",
  station: "Station",
  search: "SEARCH",
  fuel_type: "Fuel Type",
  license_no: "License No",
  date: "Date",
  count: "Count",
  liter: "Liter",
  price: "Price",
  toExcel: "To Excel",
  toPrint: "To PDF",
};