export const UserData = [
    {
        id: 1,
        year: "Tank 1",
        userGain:0,
        userLost:0
    },
      {
        id: 2,
        year:"Tank 2",
        userGain:0,
        userLost:0
    },
        {
        id: 3,
        year: "Tank 3",
        userGain:0,
        userLost:0
    },
          {
        id: 4,
        year:"Tank 4",
        userGain:0,
        userLost:0
    },
            {
        id: 5,
        year:"Tank 5",
        userGain:0,
        userLost:0
    },
    {
        id: 6,
        year: "Tank 6",
        userGain:0,
        userLost:0
    },
    {
        id: 7,
        year: "Tank 7",
        userGain:0,
        userLost:0
    }
    
]