import React from 'react'

function MainConStation() {
  return (
    <div className='w-[83%] flex items-center justify-center  h-[93svh] relative'>
      <h3>Coming Soon!</h3>
    </div>
  )
}

export default MainConStation