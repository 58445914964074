export const EnglishFuelReceiveReport = {
  main_title: "Receive Fuel Report",
  start_date: "From Date",
  end_date: "To Date",
  tank_no: "Tank No.",
  fuel_type: "Fuel Type",
  station: "Station",
  search: "SEARCH",
  receive_date: "Receive Date",
  location: "Location",
  fuel_in_code: "Fuel in Code",
  driver: "Driver",
  bowser_no: "Bowser No.",
  tank: "Tank",
  tank_capacity: "Tank Capacity",
  receive_volume: "Receive Volume",
  balance: "Balance",
  total_record: "Total Record",
  liter: "Liter",
  gallon: "Gallon",
  toExcel: "To Excel",
  toPrint: "To PDF",
};