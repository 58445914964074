export const EnglishMainHomePage = {
  daily_sale_report: "Daily Sale Report", //发票报告
  daily_sale_categories_report: "Daily Sale Categories Report",
  online_monitoring_sale_report: "Online Monitoring Sale Report",
  weekly_sale_report: "Weekly Report",
  sale_summary_by_station: "Sale Summary By Station",
  statement_report: "Nozzle Details Report",
  diffrent_totalizer: "Different Totalizers",
  tank_data: "Tank Report",
  fuel_receive_report: "Fuel In Report",
  fuel_balance_report: "Tank Report",
  stock_balance_report: "Stock Balance Report",
  fuel_type_balance: "Fuel Type Balance",
  daily_sale_summary_report: "Daily Sale Summary Report",
  check_now: "Check Now", //checknot查看
  PPRD_reports: "PPRD 7 Reports",
  reports: " Other Reports",
  sale_detail: "Sale Detail",
  pump_report: "Pump Report",
};
