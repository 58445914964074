export const EnglishCompanyTables = {
  station: "Station",
  company_tables: "Company Tables",
  start_date: "From Date",
  end_date: "To Date",
  fuel_type_total: "Fuel Type Total",
  no: "no",
  fuel_type: "Fuel Type",
  liter: "Liter",
  price: "Price",
  amount: "Amount",
  remark: "Remark",
  pump_test_tabel: "Pump Test Tabel",
  toExcel: "To Excel",
  toPrint: "To PDF",
  office_use: "Office Use ( Bowser Car )",
  station_use: "Station Use Tabel",
  station_tank_balance: "Station Tank Balance",
  tank_no: "Tank No.",
  opening: "Opening",
  closing: "Closing",
  isue: "Isue",
  blance: "Balance",
  today_tank: "Today Tank",
  yesterday_tank: "Yesterday Tank",
  tank_isue: "Tank Isue",
  today_gl: "Today G/L",
  monthly_gl: "Monthly G/L",
  pump_meter: "Pump Meter",
  stock_balance_report: "Stock Balance Report",
  tank: "Tank",
  receive: "Receive",
  issue: "Issue",
  adjust: "Adjust",
  balance: "Balance",
  total_issue: "Total Issue",
  total_gl: "Total G/L",
  search: "SEARCH",
  to_adjust: "To Adjustment",
  total_record: "Total Records",
  go_back: "Go Back",
};