export const MyanmarWeeklySaleReport = {
  main_title: "အပတ်စဉ်အရောင်းစာရင်း",
  start_date: "စတင်ရက်",
  end_date: "ပြီးဆုံးရက်",
  station: "စတေရှင်",
  search: "ရှာဖွေသည်",
  no: "စဉ်",
  import_company: "အသွင်း ကုမ္ပဏီ",
  company_name: "ကုမ္ပဏီ နာမည်",
  station_name: "စတေရှင် နာမည်",
  pprd_license: "PPRD လိုင်စင်နံပါတ်",
  liter: "လီတာ",
  total_sale: "စုစုပေါင်းအရောင်း",
  to: "မှ",
  average_sale_per_day: "တစ်ရက်ပျမ်းမျှ အရောင်း (ဂါလံ)",
  toExcel: "Excel သို့ပြောင်းရန်",
  toPrint: "Print ထုတ်ရန်",
  Station_name: "ဆိုင်အမည်", //update
  Township: "မြို့နယ်",
  State: "ပြည်နယ်/တိုင်း",
  Open: "အဖွင့် စတော့ (ဂါလံ)",
  Close: "အပိတ်စတော့ (ဂါလံ)",
  Sale: "ရောင်းချမှုပမာဏ (ဂါလံ)",
  Adj: "ချိန်ညှိမှု (ဂါလံ)",
  Remark: "မှတ်ချက်",
  receive: "ဆီလက်ခံ (ဂါလံ)",
  capacity: "သိုလှောင်နိုင်မှု (ဂါလံ)",
  own: "ကိုယ်ပိုင်ဆိုင်/အဖော်ဆိုင်",
  req: "POစစ်ဆေးသည့်နေ့",
};
