export const EnglishStatementReport = {
  main_title: "Nozzle Detail Report",
  start_date: "From Date",
  end_date: "To Date",
  station: "Station",
  search: "SEARCH",
  station_name: "Station Name",
  nozzle_no: "Nozzle No",
  fuel_type: "Fuel Type",
  price_per_liter: "Price Per Liter",
  total_sale_liter: "Total Sale Liter",
  total_price: "Total Price",
  totalizer_opening: "Totalizer Opening",
  totalizer_closing: "Totalizer Closing",
  toExcel: "To Excel",
  liter: "Li",
  kyat: "Kyats",
  toPrint: "To PDF",
};