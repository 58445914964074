export const MyanmarTotalizerDifferent = {
  mainTitle: "Totalizer ခြားနားခြင်း",
  startDate: "စတင်ရက်",
  endDate: "ပြီးဆုံးရက်",
  station: "စတေရှင်",
  fuel_type: "ဆီအမျိုးအစား",
  search: "ရှာဖွေသည်",
  station_name: "စတေရှင် နာမည်",
  nozzle_no: "ဆီဂေါက် နံပါတ်",
  fuel_type: "ဆီအမျိုးအစား",
  price_per_liter: "တစ်လီတာ ရောင်းစျေး",
  total_sale_liter: "စုစုပေါင်းလီတာ ရောင်းစျေး",
  total_price: "စုစုပေါင်းစျေး",
  totalizer_opening: "Totalizer အဖွင့်",
  totalizer_closing: "Totalizer အပိတ်",
  totalizer_different: "Totalizer ခြားနားခြင်း",
  toExcel: "Excel သို့ပြောင်းရန်",
  toPrint: "Print ထုတ်ရန်",
  liter: "လီတာ",
  kyat: "ကျပ်",
  sale_date_time: "ရောင်းချိန်",
  Station_name: "ဆိုင်အမည်", //update
  Township: "မြို့နယ်",
  State: "ပြည်နယ်/တိုင်း",
  Open: "အဖွင့် စတော့ (ဂါလံ)",
  Close: "အပိတ်စတော့ (ဂါလံ)",
  Sale: "ရောင်းချမှုပမာဏ (ဂါလံ)",
  Adj: "ချိန်ညှိမှု (ဂါလံ)",
  pprd_no: "PPRD လိုင်စင်နံပါတ်",
  Remark: "မှတ်ချက်",
  receive: "ဆီလက်ခံ (ဂါလံ)",
  pump_title: "ပန့်အစီရင်ခံစာ",
  price: "ရောင်းစျေး",
  sale_gallon: "ရောင်းချမှုစုစုပေါင်း ဂါလန်",
  pump_test: "လောင်စာဆီပမ်စမ်းသပ်မှု",
  other: "အခြား",
};
