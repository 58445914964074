export const EnglishDailySaleCategoriesReport = {
  mainTitle: "Daily Sale Categories Report",
  startDate: "From Date",
  endDate: "To Date",
  station: "Station",
  search: "Search",
  no: "Sr No.",
  content: "Categories",
  saleDepartment: "Sales department",
  fuelType: "Fuel Type",
  count: "No.",
  liter: "Liter",
  remark: "Remark",
  cycle: "Cycle",
  cycle3Wheel: "Cycle(3Wheels)",
  car: "Car",
  bus: "Bus",
  city: "City",
  highWay: "High Way",
  lightTruck: "Light Truck",
  heavyTruck: "Heavy Truck",
  trailer: "Trailer",
  htawlargyi: "Htawlargyi",
  tractor: "Tractor",
  smallTractor: "Small Tractor",
  heavyMachinery: "Heavy Machinery",
  commericalVehicle: "Commerical Vehicle",
  phoneTower: "Phone Tower",
  industrialZone: "Industrial Zone",
  withABucket: "Drum",
  generatorIndustry: "Generator (Industry)",
  agricultureMachine: "Agriculture Machine",
  generatorHomeUse: "Generator(Home Use)",
  hospital: "Hospital",
  school: "School",
  superMarket: "Super Market",
  hotel: "Hotel",
  housing: "Housing",
  boat: "Boat",
  total: "Total",
  toExcel: "To Excel",
  toPrint: "To PDF",
  a: "i",
  b: "ii",
  c: "iii",
  d: "iv",
  e: "v",
  f: "vi",
  g: "vii",
  h: "viii",
  i: "ix",
  aa: "1",
  bb: "2",
  cc: "3",
  dd: "4",
  ee: "5",
  ff: "6",
  gg: "7",
  hh: "8",
  ii: "9",
  jj: "10",
  kk: "11",
  ll: "12",
  mm: "13",
  nn: "14",
};