export const EnglishTotalizerDifferent = {
  mainTitle: "Totalizer Different",
  startDate: "From Date",
  endDate: "To Date",
  station: "Station",
  fuel_type: "Fuel Type",
  search: "SEARCH",
  station_name: "Station Name",
  nozzle_no: "Pump/Nozzle No.",
  fuel_type: "Fuel Type",
  price_per_liter: "Price Per Liter",
  total_sale_liter: " Sale Liter",
  total_price: "Total Price",
  totalizer_opening: " Opening Totalizer No.",
  totalizer_closing: " Closing Totalizer No.",
  totalizer_different: " Difference",
  toExcel: "To Excel",
  liter: "Li",
  kyat: "Kyats",
  toPrint: "To PDF",
  sub_title: "Pump Report",
  Station_name: "Station Name", //update
  Township: "Township",
  sale_date_time: "Date",
  State: "State/Division",
  Open: "Opening Stock (Gallon)",
  Close: "Closing Stock (Gallon)",
  Sale: "Sale Volume (Gallon)",
  Adj: "Adjustment (Gallon)",
  Remark: "Remark",
  receive: "Received (Gallon)",
  pump_title: "Pump Report",
  pprd_no: "PPRD License No.",
  price: "Price",
  sale_gallon: " Sale Gallon",
  pump_test: "Pump Test",
  other: "Other",
};
