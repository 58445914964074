export const EnglishWeeklySaleReport = {
  main_title: "Weekly Sale Report",
  start_date: "From Date",
  end_date: "To Date",
  station: "Station",
  search: "SEARCH",
  no: "No",
  import_company: "Importer Company",
  company_name: "Company",
  station_name: "Fuel Station",
  pprd_license: "PPRD License No.",
  liter: "Li",
  total_sale: "Total Sale From",
  to: "to",
  average_sale_per_day: "Daily Average Sale (Gallon)",
  toExcel: "To Excel",
  toPrint: "To PDF",
  Station_name: "Station Name", //update
  Township: "Township",
  State: "State /Division",
  Open: "Opening Stock (Gallon)",
  Close: "Closing Stock (Gallon)",
  Sale: "Sale Volume (Gallon)",
  Adj: "Adjustment (Gallon)",
  Remark: "Remark",
  receive: "Received (Gallon)",
  capacity: "Storage Capacity (Gallon)",
  own: "Own Shop / Partner Shop",
  req: "PO Requested Day(s)",
};
